import $ from 'jquery';
window.$ = window.jQuery = $;

(function ($) {
  const BREAKPOINTS = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 11400
  };
  const HTML = document.querySelector('html');
  const BODY = document.querySelector('body');
  const BGCOLOR = "#1E1E1E";
  const ROCKBLUE = "#9EAFD0";
  let is_handheld = false;
  let browserName;

  class Front {
    /**
     * Init
     */
    init() {    
      this.initBrowserDetect();
      this.initHandHeldDetect();
      this.initToggleButtons();
      this.initServices();
      this.initScrollTo();
      this.initMosaicTouch();
      !is_handheld && this.initCanvasHeader();
    }

    initMosaicTouch() {
      document.querySelectorAll('.mosaic--item').forEach(el => {
        el.addEventListener('touchstart', function() {
            // Toggle the 'active' class on touch
            if (el.classList.contains('active')) {
                el.classList.remove('active');
                el.blur();
              } else {
                // First, remove 'active' class from all items
                document.querySelectorAll('.mosaic--item.active').forEach(activeEl => {
                  activeEl.classList.remove('active');
                  activeEl.blur();
                });
                // Then, add 'active' class to the touched element
                el.classList.add('active');
            }
        }, {passive: true});
      });
    }

    initScrollTo() {
      document.addEventListener('DOMContentLoaded', function() {
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
          anchor.addEventListener('click', function(e) {
            e.preventDefault();
            const targetId = this.getAttribute('href');
            const targetElement = document.querySelector(targetId);

            if (targetElement) {
              targetElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
              });
            }
          });
        });
      });
    }

    initHandHeldDetect() {
      if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)) {
        is_handheld = true
        HTML.classList.add('is-handheld')
      }
    }

    initBrowserDetect() {
      let userAgent = navigator.userAgent;
      if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = 'chrome';
      } else if(userAgent.match(/firefox|fxios/i)){
        browserName = "firefox";
      }  else if(userAgent.match(/safari/i)){
        browserName = "safari";
      }else if(userAgent.match(/opr\//i)){
        browserName = "opera";
      } else if(userAgent.match(/edg/i)){
        browserName = "edge";
      }else{
        browserName="unknown";
      }
        HTML.classList.add('browser-' + browserName)
    }

    initToggleButtons() {
      const toggleClass = 'toggle-button';
      const buttons = Array.from(document.getElementsByClassName(toggleClass));

      buttons.forEach((button) => {
        let menu = button.nextElementSibling;
        button.addEventListener('click', (e) => {
          e.preventDefault();
          if (button.classList.contains('active')) {
            button.classList.remove('active');
            menu.classList.remove('active');
          } else {
            button.classList.add('active');
            menu.classList.add('active');
          }
        });
      });
    }

    initCanvasHeader() {
      const imgPath = require('../img/confes-175.svg');
      this.initCanvas('header', 'canvas-header', imgPath, 6, 1);
    }

    initCanvas(boxId, canvasId, imgPath, imgOffsetLeft, imgOffsetTop) {
      const image = new Image();
      image.src = imgPath;

      const box = document.getElementById(boxId);
      const canvas = document.getElementById(canvasId);
      const ctx = canvas.getContext('2d');
      let imgPosX;
      let imgPosY;
      let imgEndPosX;
      let imgEndPosY;
      let imgCenterX;
      let imgCenterY;
      const alpha = 88;
      const shadowLength = 1100;
      const minLightLenght = 400;
      let light = { x: shadowLength + 100, y: shadowLength };
      let skips = []
      let particles = []

      class Particle {
        constructor(x, y, d) {
            this.x = x;
            this.y = y;
            this.d = d;
            this.r = d / 2;
            this.half_size = this.r;
            this.shadowLength = shadowLength + Math.random() * 50;
            this.points = 8;
        }

        getDots() {
          const full = (Math.PI * 2) / this.points;
          let points = [];
          for (let i = 0; i < this.points; i++) {
            points.push({
              x: this.x + this.half_size * Math.sin(this.r + full * i+1),
              y: this.y + this.half_size * Math.cos(this.r + full * i+1)
            });
          };
      
          return points;
        }

        drawShadow = function() {
          const dots = this.getDots();
          let angles = [];
          let points = [];
      
          for (let dot in dots) {
            const angle = Math.atan2(light.y - dots[dot].y, light.x - dots[dot].x);
            const endX = dots[dot].x + this.shadowLength * Math.sin(-angle - Math.PI / 2);
            const endY = dots[dot].y + this.shadowLength * Math.cos(-angle - Math.PI / 2);
            angles.push(angle);
            points.push({
              endX: endX,
              endY: endY,
              startX: dots[dot].x,
              startY: dots[dot].y
            });
          };
      
          for (let i = points.length - 1; i >= 0; i--) {
            let n = i == this.points-1 ? 0 : i + 1;
            ctx.beginPath();
            ctx.moveTo(points[i].startX, points[i].startY);
            ctx.lineTo(points[n].startX, points[n].startY);
            ctx.lineTo(points[n].endX, points[n].endY);
            ctx.lineTo(points[i].endX, points[i].endY);
            const gradient = ctx.createRadialGradient(light.x, light.y, 0, light.x, light.y, this.shadowLength);
            gradient.addColorStop(0, 'rgba(255,255,255,0.025)');
            gradient.addColorStop(1, 'rgba(255,255,255,0)');
            ctx.fillStyle = gradient;
            ctx.fill();
          };
        }
      }

      function setPositions() {
        imgEndPosX = parseInt(imgPosX + image.naturalWidth);
        imgEndPosY = parseInt(imgPosY + image.naturalHeight);
        imgCenterX = parseInt(imgEndPosX - image.naturalWidth / 2);
        imgCenterY = parseInt(imgEndPosY - image.naturalHeight / 2);
      }

      function setCanvas() {
        particles = [];
        skips = [];
        canvas.width =  document.body.clientWidth;
        canvas.height = box.offsetHeight;
        imgPosX = parseInt((document.body.clientWidth - box.offsetWidth )) / 2 + parseInt(getComputedStyle(box).paddingLeft) + imgOffsetLeft;
        imgPosY = parseInt(getComputedStyle(box).paddingTop) + imgOffsetTop;

        ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, imgPosX, imgPosY, image.naturalWidth, image.naturalHeight);
        setPositions();
        const imgCoordinates = ctx.getImageData(imgPosX,imgPosY, image.naturalWidth, image.naturalHeight);

        function isWhite(x,y) {
          // Check every fourth item, because each pixel takes 4 positions in the imgCoordinates array
          return (imgCoordinates.data[(y * imgCoordinates.width * 4) + (x * 4) + 3] > alpha);
        }

        function isSkip(x,y) {
          return skips.includes(x + ',' + y);
        }

        // From a starting x pixel, return the last following white pixel in the row
        function last_x(x,y) {
          let nextX = x + 1;
          // If next X is NOT white
          if (!(imgCoordinates.data[(y * imgCoordinates.width * 4) + (nextX * 4) + 3] > alpha)) {
            return x;
          }
          return last_x(nextX,y);
        }

        // From a starting y pixel in a middle x point, return the last following white pixel in the column
        function last_y(x,y) {
          let nextY = y + 1;
          // If next Y is NOT white
          if (!(imgCoordinates.data[(nextY * imgCoordinates.width * 4) + (x * 4) + 3] > alpha)) {
            return y;
          }
          return last_y(x,nextY);
        }

        function getDotData(x,y) {
          // We got the first white pixel of the dot.
          // From the first white pixel found, get the last one in the row from the same dot.
          let lastX = last_x(x,y);
          let centerX = x + (lastX - x) / 2;

          // From the centerX, get the last white pixel of the dot in the Y column
          let lastY = last_y(centerX,y);
          let height = lastY - y;
          let centerY = y + height / 2;

          return {
              x: centerX,
              y: centerY,
              d: height  //diameter
            }; 
        }

        function addToSkips(dotData) {
          skips.push('Data x:' + dotData.x + ', y:' + dotData.y + ', d:' + dotData.d);
          let radius = dotData.d / 2;
          let firstX = Math.floor(dotData.x - radius);
          let lastX = Math.ceil(dotData.x + radius);
          let firstY = Math.floor(dotData.y - radius);
          let lastY = Math.ceil(dotData.y + radius);
          for (let y = firstY; y <= lastY; y++) {
            for (let x = firstX; x <= lastX; x++) {
              skips.push(x + ',' + y);
            }
          }
        }

        // Create all particles
        // Loop through every line of grid on the Y axis
        for (let y = 0, y2 = imgCoordinates.height; y < y2; y++) {
          // Loop through every column of grid on the X axis, within the line
          for (let x = 0, x2 = imgCoordinates.width; x < x2; x++) {
            // Check if this pixel is white, and is not in skip array
            if (isWhite(x,y) && !isSkip(x,y)){

              // Get center of dot
              let dotData = getDotData(x,y);

              // Add dot to skip array
              addToSkips(dotData);

              // Create particle object
              particles.push(new Particle(Math.ceil(dotData.x) + imgPosX, Math.ceil(dotData.y) + imgPosY, dotData.d))
            }
          }
        }

        drawShadows();
      }

      function drawShadows() {
        if (browserName !== "safari") {
          for (let i = 0; i < particles.length; i++) {
            particles[i].drawShadow();
          };
        }
      }

      function draw() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, imgPosX, imgPosY, image.naturalWidth, image.naturalHeight);
        drawShadows();
        requestAnimationFrame(draw);
      }

      function drawLight() {        
        ctx.beginPath();
        ctx.arc(light.x, light.y, 5, 0, 2 * Math.PI);
        let gradient = ctx.createRadialGradient(light.x, light.y, 0, light.x, light.y, 5);
        gradient.addColorStop(0, "#fff");
        gradient.addColorStop(1, BGCOLOR);
        ctx.fillStyle = gradient;
        ctx.fill();
      }

      function updateLightPos(e) {
        const max = shadowLength - minLightLenght;
        let x = e.offsetX == undefined ? e.layerX : e.offsetX;
        let y = e.offsetY == undefined ? e.layerY : e.offsetY;
        let distanceX = Math.abs(x - imgCenterX);
        let distanceY = Math.abs(y - imgCenterY);
        let distance = Math.sqrt(distanceX ** 2 + distanceY ** 2);
        let difference = distance - max;


        // * This commented part limits the distance of the light, always providing some light stream
        // * even when the mouse is really far away.

        // if (difference > 0) {
        //   light.x = x > imgCenterX ? x - distanceX * (difference / distance) : x + distanceX * (difference / distance);
        //   light.y = y > imgCenterY ? y - distanceY * (difference / distance) : y + distanceY * (difference / distance);
        // } else {
          light.x = x;
          light.y = y;
        // }
      }

      function reinit() {
        setCanvas();
        drawLight();
      }

      image.onload = function() {
        reinit();
        draw();
      }

      window.onresize = reinit;

      canvas.onmousemove = function(e) {
        updateLightPos(e);
      }

    }

    initServices() {
      const toggles = document.querySelectorAll('[data-service]');

      Array.from(toggles).forEach((element) => {
        const img = document.getElementById(element.dataset.service);
        element.addEventListener('mouseover', () => {
          img.classList.add('active');
        })
        element.addEventListener('mouseleave', () => {
          img.classList.remove('active');
        })
      });
    }
  }

  const front = new Front();
  front.init();

}) (jQuery);

